import React, { Dispatch, SetStateAction } from "react";
import { Box, Typography, Popover, Modal, FormControl, MenuItem, Link } from "@mui/material";
import Cookies from "js-cookie";
import { fileStautusUpdate } from "../../services/api/fileLibrary";
import { bugStautusUpdate } from "../../services/api/dashboard";
import { enrollmentStautusUpdate } from "../../services/api/student";
import { useDispatch } from "react-redux";
import { setRefetchContent } from "../../store/filelibrary.slice";
import { setRefetchContent as setRefetchContentstudent } from "../../store/student.slice";
import ScheduleCalendarComponent from "../FileUpload/SceduleCalendar";
import Select from "@mui/material/Select";
import { timeValue } from "../../utils/uploadTime";
import moment from "moment";

type ToastState = {
  type: string;
  message: string;
};
interface dropProps {
  sx: object;
  status: string;
  type: string;
  timestamp?: string;
  dropdown?: boolean;
  disabled?: boolean;
  fileId?: number;
  onClickCallback: (status: string) => void;
  setToast?: Dispatch<SetStateAction<ToastState | null>>;
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: "8px",
  bgcolor: '#F4F6FF',
  p: 4,
};


const data = [
  {
    label: "Needs attention",
    status: "needs attention",
    color: "#C61341",
    bgColor: "#FFEEF2",
    type: "bugs",
  },
  {
    label: "In review",
    status: "in review",
    color: "#0069DB",
    bgColor: "#E6F2FF",
    type: "bugs",
  },
  {
    label: "Resolved",
    status: "resolved",
    color: "#007634",
    bgColor: "#E6F6ED",
    type: "bugs",
  },
  {
    label: "Draft",
    status: "draft",
    color: "#994d00",
    bgColor: "#ffddc5",
    type: "published",
  },
  // {
  //   label: "Uploading",
  //   status: "uploading",
  //   color: "#512EC6",
  //   bgColor: "#EEE8FF",
  //   type: "published",
  // },
  {
    label: "Scheduled",
    status: "scheduled",
    color: "#0069DB",
    bgColor: "#E6F2FF",
    type: "published",
  },
  {
    label: "Published",
    status: "published",
    color: "#007634",
    bgColor: "#E6F6ED",
    type: "published",
  },
  {
    label: "Not Started",
    status: "not started",
    color: "#3B4478",
    bgColor: "#EBECF1",
    type: "status",
  },
  {
    label: "In review",
    status: "in review",
    color: "#B85800",
    bgColor: "#FFF0E2",
    type: "status",
  },
  {
    label: "Completed",
    status: "completed",
    color: "#007668",
    bgColor: "#E3F5F5",
    type: "status",
  },
  {
    label: "Enrolled",
    status: "enrolled",
    color: "#007668",
    bgColor: "#E3F5F5",
    type: "enrolmentStatus",
  },
  {
    label: "Unenrolled",
    status: "unenrolled",
    color: "#512EC6",
    bgColor: "#EEE8FF",
    type: "enrolmentStatus",
  },
  {
    label: "Trialing",
    status: "trialing",
    color: "#0069DB",
    bgColor: "#E6F2FF",
    type: "enrolmentStatus",
  },
  {
    label: "Requires invoice",
    status: "requiresInvoice",
    color: "#B85800",
    bgColor: "#FFF0E2",
    type: "enrolmentStatus",
  },
];

const StatusDropdown = ({
  sx,
  status,
  timestamp,
  disabled = false,
  type,
  fileId,
  onClickCallback,
  setToast,
}: dropProps) => {
  const verifyAccessString = Cookies.get("authAccess");
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = React.useState<string | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isHovering, setIsHovering] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState<string>(status);
  const [selectedColor, setSelectedColor] = React.useState<string>("");
  const [selectedBgColor, setSelectedBgColor] = React.useState<string>("");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  console.log("aaaaa", selectedLabel);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleScheduleFIle = async () => {
    try {
      const { bgColor = "", color = "", label = "", status = "" } = data.filter((i) => i.type == type && i.status === "scheduled")[0] || {}
      const payload: {
        fileId: number[];
        filestatus?: string;
        publishStatus?: string;
        publishedTime?: string;
      } = {
        fileId: [fileId!],
      };
      if (type === "status") {
        payload.filestatus = status;
      } else if (type === "published") {
        payload.publishStatus = status;
      }
      if (status === "scheduled") {
        const dateObj = moment(selectedEndDate);
        const timeString = selectedTime;
        const timeObj = moment(timeString, "HH:mm A");
        dateObj.set({
          hour: timeObj.hour(),
          minute: timeObj.minute(),
        });
        const timestamp = dateObj.format("YYYY-MM-DD HH:MM:ss");
        payload.publishedTime = timestamp
      }

      const response = await fileStautusUpdate(payload, verifyAccess.token);
      if (response && response.data) {
        const successMessage = response.data.message;
        setToast &&
          setToast({
            type: "success",
            message: successMessage,
          });
        dispatch(setRefetchContent({ shouldRefetch: new Date().getMilliseconds() }))
        setSelectedLabel(label);
        setSelectedColor(color);
        setSelectedBgColor(bgColor);
        onClickCallback(status);
        setAnchorEl(null);
        setOpenModal(false);
        setSelectedTime(null);
        setSelectedEndDate(null);

      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
        setAnchorEl(null);
        setOpenModal(false);
        setSelectedTime(null);
        setSelectedEndDate(null);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setAnchorEl(null);
      setOpenModal(false);
      setSelectedTime(null);
      setSelectedEndDate(null);
    }

  }
  const handleChange = async (
    dynamicValue: string,
    label: string,
    color: string,
    bgColor: string,
  ) => {
    if (type === "bugs") {
      try {
        const payload: {
          bugid: number;
          status?: string;
        } = {
          bugid: fileId!,
        };
        payload.status = dynamicValue;

        const response = await bugStautusUpdate(payload, verifyAccess.token);
        if (response && response.data) {
          const successMessage = response.data.message;
          setToast &&
            setToast({
              type: "success",
              message: successMessage,
            });
          dispatch(setRefetchContent({ shouldRefetch: new Date().getMilliseconds() }))

        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast &&
            setToast({
              type: "error",
              message: errorMessage,
            });
        }
      } catch (error) {
        console.error("Error updating status:", error);
      }
    } else if (type === "enrolmentStatus") {
      try {
        const payload: {
          studentid: number;
          status?: string;
        } = {
          studentid: fileId!,
        };
        payload.status = dynamicValue;

        const response = await enrollmentStautusUpdate(
          payload,
          verifyAccess.token,
        );
        if (response && response.data) {
          const successMessage = response.data.message;
          setToast &&
            setToast({
              type: "success",
              message: successMessage,
            });
          dispatch(setRefetchContentstudent({ shouldRefetch: new Date().getMilliseconds() }))

        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast &&
            setToast({
              type: "error",
              message: errorMessage,
            });
        }
      } catch (error) {
        console.error("Error updating status:", error);
      }
    } else {
      try {
        const payload: {
          fileId: number[];
          filestatus?: string;
          publishStatus?: string;
        } = {
          fileId: [fileId!],
        };
        if (type === "status") {
          payload.filestatus = dynamicValue;
        } else if (type === "published") {
          payload.publishStatus = dynamicValue;
        }

        const response = await fileStautusUpdate(payload, verifyAccess.token);
        if (response && response.data) {
          const successMessage = response.data.message;
          setToast &&
            setToast({
              type: "success",
              message: successMessage,
            });
          dispatch(setRefetchContent({ shouldRefetch: new Date().getMilliseconds() }))

        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast &&
            setToast({
              type: "error",
              message: errorMessage,
            });
        }
      } catch (error) {
        console.error("Error updating status:", error);
      }
    }
    setSelectedLabel(label);
    setSelectedColor(color);
    setSelectedBgColor(bgColor);
    onClickCallback(dynamicValue);
    setAnchorEl(null);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const getStatus = data.find((i) => i.status == status && i.type == type);
  return (
    <>
      <Box
        aria-describedby={id}
        component={"button"}
        bgcolor={anchorEl !== null ? "#FFF8EC" : "white"}
        display={"flex"}
        flexDirection={"row"}
        gap={"8px"}
        alignItems={"center"}
        padding={"8px"}
        borderRadius={"8px"}
        border={"none"}
        disabled={disabled}
        onClick={handleClick}
        sx={{
          ...sx,
          "&:hover": {
            bgcolor: "#FFF8EC",
          },
          position: "relative",
          width: "178px",
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"8px"}
          alignItems={"center"}
          bgcolor={selectedBgColor || getStatus?.bgColor}
          paddingX={"8px"}
          paddingY={"4px"}
          borderRadius={"16px"}
          width={"fit-content"}
          sx={{ background: selectedColor || getStatus?.color ? "" : "#EEE8FF" }}
        >
          <Box
            width={"7px"}
            height={"7px"}
            bgcolor={selectedColor || getStatus?.color ? selectedColor || getStatus?.color : "#512EC6"}
            borderRadius={"50%"}
            display={"inline-block"}
          />
          <Typography
            color={selectedColor || getStatus?.color ? selectedColor || getStatus?.color : "#512EC6"}
            variant="detailBold"
            sx={{
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          >
            {selectedLabel || getStatus?.label}
          </Typography>
        </Box>
        {status !== "draft" &&
          <>
            {timestamp && (
              <Typography
                color={"#696969"}
                variant="bodySm"
                sx={{
                  color: "#696969",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "5px",
                }}
              >
                {timestamp}
              </Typography>
            )}
          </>
        }

        {isHovering || anchorEl ? (
          <Box
            sx={{
              position: "absolute",
              right: 10,
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
          </Box>
        ) : null}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            borderRadius: "16px",
          }}
          display={"flex"}
          flexDirection={"column"}
        >
          {data
            .filter((i) => i.type == type)
            .map((item, id) => {
              return (
                <Box
                  key={id}
                  component={"div"}
                  paddingX={"12px"}
                  paddingY={"8px"}
                  display={"flex"}
                  flexDirection={"row"}
                  gap={"8px"}
                  alignItems={"center"}
                  borderBottom={
                    id != data.length - 1 ? "1px solid #D2D2D0" : "0px"
                  }
                  onClick={() => {
                    item.status !== "scheduled" ? handleChange(
                      item.status,
                      item.label,
                      item.color,
                      item.bgColor,
                    ) : setOpenModal(true)
                  }
                  }
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"8px"}
                    alignItems={"center"}
                    bgcolor={item?.bgColor}
                    paddingX={"8px"}
                    paddingY={"4px"}
                    borderRadius={"16px"}
                    width={"fit-content"}
                  >
                    <Box
                      width={"7px"}
                      height={"7px"}
                      bgcolor={item?.color}
                      borderRadius={"50%"}
                      display={"inline-block"}
                    />
                    <Typography color={item?.color} variant="detailBold" sx={{ cursor: "pointer", }}>
                      {item?.label}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Popover>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Schedule files
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            Select date and time for scheduling
          </Typography>
          <Box>
            <ScheduleCalendarComponent
              selectedStartDate={null}
              handleDateChange={handleDateChange}
              selectedEndDate={selectedEndDate} // Pass selectedEndDate as prop
            />
          </Box>
          <Typography
            sx={{
              marginBottom: "10px",
              marginTop: "4px",
            }}
          >
            <label
              style={{
                display: "block",
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              Time
            </label>
            <FormControl
              sx={{
                "&.MuiFormControl-root": {
                  margin: "0px",
                  width: "100%",
                },
                "&.MuiFormControl-root .MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "&.MuiFormControl-root .MuiSelect-select": {
                  padding: "12px 16px",
                },
              }}
            >
              <Select
                value={selectedTime}
                onChange={(event) => setSelectedTime(event.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  ".MuiSvgIcon-root": {
                    color: "#8E8D8A !important",
                    fill: "#8E8D8A !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {timeValue.map((tm: { value: string; time: string }, index) => (
                  <MenuItem key={index} value={tm.value}>
                    {tm.time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Link
              onClick={() => handleScheduleFIle()}
              underline="none"
              sx={{
                paddingX: "20px",
                bgcolor: "#FFA737",
                borderRadius: "8px",
                height: "44px",
              }}
              component={"button"}
            >
              <Typography color={"#333"} variant="cbutton">
                Confirm & Schedule
              </Typography>
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default StatusDropdown;
