import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import { useLocation } from "react-router-dom";
import Toast from "../components/ToastMessage/Toast";

const drawerWidth = 90;

interface dashboardprops {
  children: React.ReactNode;
}

export default function Dashboard({ children }: dashboardprops) {
  const location = useLocation().pathname;
  const [openFilePopup, setOpenFilePopup] = React.useState(false);
  const [uploadingFile, setUploadingFile] = React.useState(false);

  const handleFilePopupOpen = () => {
    setOpenFilePopup(true);
  };

  const handleFilePopupClose = () => {
    setOpenFilePopup(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Toast/>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Header
          open={openFilePopup}
          handleClose={handleFilePopupClose}
          handleFilePopupOpen={handleFilePopupOpen}
          uploadingFile={uploadingFile}
          setUploadingFile={setUploadingFile}
          // setShowProgress={setShowProgress}
        />
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
        }}
      >
        <Toolbar />
        <Box>
          <SideNav />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor:
            location === "/file-library" ||
              location === "/class-recordings" ||
            location === "/subject" ||
            location === "/student"
              ? "#fff"
              : "#F4F6FF",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          paddingX: "23px",
          paddingY: "37px",
          mt: "76px",
          height: "calc(100vh - 76px)",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
