import apiClientFileUpload from "./apiClientFileUpload";
import client from "./client";

export const getClassLibraryPaginationData = async (
  token: string,
  page: number,
  limit: number,
  status: any,
  params: object
) =>
  await apiClientFileUpload.get(
    `/class/cls-rec-list?status=${status}&page=${page}&limit=${limit}`,
    {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getEventDetails = async (token: string, eventID: string) =>
  await client.get(`/files/events/${eventID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFileTypeCount = async (token: string) =>
  await client.get("/filetype/count", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const viewRecordingFileDetails = async (token: string, id: number) =>
  await apiClientFileUpload.get(`/class/cls-rec-file-details/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fileRecStautusUpdate = async (
  payload: object,
  token: string | null
) =>
  await apiClientFileUpload.post("/class/cls-rec-status-update", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const recordingTimestamp = async (
  payload: string,
  fileId: number,
  token: string | null
) =>
  await client.post(`/files/generateChapters/${fileId}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/plain",
    },
  });
