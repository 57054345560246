import { Box, Typography } from "@mui/material";
import React from "react";
import IconComponent from "../Icons";
import { useNavigate, useLocation } from "react-router-dom";

const iconColors = { inactive: "#F4F6FF", active: "#FFA737" };

interface Link {
  label: string;
  icon: string;
  path: string;
}

const links: Link[] = [
  {
    label: "Dashboard",
    icon: "home",
    path: "/dashboard",
  },
  {
    label: "AOS library",
    icon: "folderOpen",
    path: "/file-library",
  },
  {
    label: "Class Rec",
    icon: "folderOpen",
    path: "/class-recordings",
  },
  {
    label: "Subjects",
    icon: "bookOpen",
    path: "/subject",
  },
  {
    label: "Students",
    icon: "student",
    path: "/student",
  },
];

const iconStyle = {
  padding: "10px",
  paddingBottom: "6px",
  borderRadius: "12px",
  "&:hover": { scale: "1.2" },
};

const activeStyle = { ...iconStyle, "&:hover": {}, bgcolor: "#3B4478" };

const SideNav = () => {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const handleItemClick = (path: string) => {
    navigate(path);
  };
  return (
    <Box sx={{ width: "90px", flexDirection: "column" }}>
      {links.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{ paddingX: "12px", paddingY: "16px", cursor: "pointer" }}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            onClick={() => handleItemClick(item.path)}
          >
            <Box sx={location == item.path ? activeStyle : iconStyle}>
              {item.icon && (
                <IconComponent
                  name={item.icon}
                  fill={
                    location == item.path
                      ? iconColors.active
                      : iconColors.inactive
                  }
                />
              )}
            </Box>
            <Typography
              sx={{
                fontSize: 12,
                color: "#fff",
                fontFamily: "Inter",
                fontWeight: 600,
              }}
            >
              {item.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default SideNav;
