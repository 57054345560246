import React from "react";
import { Tabs, Tab, Box, Typography, Popover, Skeleton } from "@mui/material";
import IconComponent from "../Icons";
import TableComponent from "./tableComponent";
import FilterComponent from "./filterComponent";
import { SearchComponent } from "./searchComponent";
import ToastMessage from "../ToastMessage";
import Cookies from "js-cookie";
import SelectedFilter from "./selectedFilter";
import {
  // fileStautusUpdate,
  getFileTypeCount,
} from "../../services/api/fileLibrary";
import { getSubjectList } from "../../services/api/student";
import { getTypeList } from "../../services/api/subjects";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fileRecStautusUpdate, getClassLibraryPaginationData } from "../../services/api/classLibrary";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type RowData = {
  id: number;
  File_name: string;
  Publish_status: string;
  Publish_timestamp: string;
  upload_timestamp: string;
  file_status: string;
  bug_count: number;
  file_description: string;
};

interface TypeList {
  id: number;
  file_type: string;
}

interface SubjectList {
  id: number;
  sub_code: string;
  subject: string;
  label: string;
}

type GetFilter = {
  file_status?: string;
  from_date?: string;
  to_date?: string;
  subject_id?: string;
  type_id?: string;
  alert_status?: string;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ClassRecLibraryComponent = () => {
  const { shouldRefetch } = useSelector((state: RootState) => state.fileLibrary);
  const [value, setValue] = React.useState(0);
  const [currentPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [status, setStatus] = React.useState("all");
  const [rowData, setRowData] = React.useState<RowData[]>([]);
  const [fileBugCount, setFileBugCount] = React.useState(0);
  const [fileScheduledCount, setFileScheduledCount] = React.useState(0);
  const [loadingCount, setLoadingCount] = React.useState(true);
  const [loadingTableCount, setLoadingTableCount] = React.useState(true);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [typeData, setTypeData] = React.useState<TypeList[]>([]);
  const [subjectData, setSubjectData] = React.useState<SubjectList[]>([]);
  const [filtersOption, setFiltersOption] = React.useState<GetFilter>({});
  const [allCount, setAllCount] = React.useState<string>("0");
  const [publishedCount, setPublishedCount] = React.useState<string>("0");
  const [unpublishedCount, setUnublishedCount] = React.useState<string>("0");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedRowCount(0);
    if (newValue === 0) setStatus("all");
    else if (newValue === 1) setStatus("published");
    else if (newValue === 2) setStatus("unpublished");
  };


  const handleFullExportCsv = () => {
    type fullData = {
      id: number;
      File_name: string;
      Publish_status: string;
      upload_timestamp: string;
      file_status: string;
    };

    const exportData: fullData[] = rowData.map(
      ({ id, File_name, Publish_status, file_status, upload_timestamp }) => ({
        id,
        File_name,
        Publish_status,
        file_status,
        upload_timestamp,
      }),
    );
    const tableHeading = Object.keys(exportData[0]).join(",");
    const tableData = exportData
      ?.map((row) => Object.values(row).join(","))
      .join("\n");

    const csvContent = `${tableHeading}\n${tableData}`;
    downloadCSV(csvContent);
    setToast({ type: "success", message: "CSV file is exported successfully" });
    handleClose();
  };

  const handlePageExportCsv = () => {
    type pageData = {
      id: number;
      File_name: string;
      Publish_status: string;
      upload_timestamp: string;
      file_status: string;
    };

    const start = (currentPage - 1) * 15;
    const end = Math.min(currentPage * 15, rowData.length);
    const pageRows = rowData.slice(start, end);

    const exportData: pageData[] = pageRows.map(
      ({ id, File_name, Publish_status, file_status, upload_timestamp }) => ({
        id,
        File_name,
        Publish_status,
        file_status,
        upload_timestamp,
      }),
    );

    const tableHeading = Object.keys(exportData[0]).join(",");
    const tableData = exportData
      ?.map((row) => Object.values(row).join(","))
      .join("\n");

    const csvContent = `${tableHeading}\n${tableData}`;
    downloadCSV(csvContent);

    handleClose();
  };

  const downloadCSV = (csvContent: string) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "table_data.csv";
    link.click();
  };

  const iconColors = { inactive: "#8E8D8A", active: "#FFA737" };
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [isActive1, setIsActive1] = React.useState(false);
  const [isActive2, setIsActive2] = React.useState(false);
  const [selectedRowCount, setSelectedRowCount] = React.useState(0);
  const [selectedRowIds, setSelectedRowIds] = React.useState<number[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,  // default value will be used if not passed */
    pageSize: 15,
  });
  const [isLoading, setLoading] = React.useState(true)

  const handleClick1 = () => {
    setIsActive1(!isActive1);
    setIsActive2(false);
    setFilter(isActive1 ? "" : "bugreport");
  };

  const handleClick2 = () => {
    setIsActive2(!isActive2);
    setIsActive1(false);
    setFilter(isActive2 ? "" : "SCHEDULED");
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelectionChange = (selection: string | unknown[]) => {
    const selectedIds = Array.isArray(selection)
      ? selection.map((id) => parseInt(id as string))
      : [];
    setSelectedRowIds(selectedIds);
    setSelectedRowCount(selection.length);
  };

  const fetchFileCount = async () => {
    try {
      const token = verifyAccess.token;
      const response = await getFileTypeCount(token);
      console.log("loglog", response);
      if (response.status === 200) {
        if (response.data.data[0].filesWithBugCount) {
          setFileBugCount(response.data.data[0].filesWithBugCount);
        }
        if (response.data.data[0].totalScheduledCount) {
          setFileScheduledCount(response.data.data[0].totalScheduledCount);
        }
        setLoadingCount(false);
      } else {
        setLoadingCount(false);
      }
    } catch (error) {
      setLoadingCount(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatas = async () => {
    try {
      setLoading(true);
      const token = verifyAccess.token;
      const params = {
        filter: filter === "" ? undefined : filter,
        ...filtersOption,
        status,
        search: searchQuery !== "" ? searchQuery : undefined,
      };
      const response = await getClassLibraryPaginationData(token, (paginationModel.page), (paginationModel.pageSize), status, params);
      if (response.status === 200) {
        setRowData(response.data.data.files);
        setAllCount(response.data?.data?.totalCount);
        setPublishedCount(response.data?.data?.published_files_count);
        setUnublishedCount(response.data?.data?.unpublished_files_count);
        setLoadingTableCount(false);
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchDatas();
  }, [paginationModel, status]);


  const [rowCountState, setRowCountState] = React.useState(
    allCount
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      allCount !== undefined
        ? allCount
        : prevRowCountState,
    );
  }, [allCount, setRowCountState]);


  React.useEffect(() => {
    fetchFileCount();
    fetchSubjectData();
    fetchTypeData();
  }, []);

  React.useEffect(() => {
    fetchDatas();
  }, [filter, status, searchQuery, filtersOption]);
  React.useEffect(() => {
    shouldRefetch !== null && fetchDatas();
  }, [shouldRefetch]);

  const fetchSubjectData = async () => {
    try {
      const response = await getSubjectList(verifyAccess.token);
      setSubjectData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTypeData = async () => {
    try {
      const response = await getTypeList(verifyAccess.token);
      setTypeData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectedFilter = async (type: string, dynamicValue: string) => {
    try {
      const payload: {
        fileId: number[];
        filestatus?: string;
        publishStatus?: string;
      } = {
        fileId: selectedRowIds,
      };
      if (type === "status") {
        payload.filestatus = dynamicValue;
      } else if (type === "published") {
        payload.publishStatus = dynamicValue;
      }

      const response = await fileRecStautusUpdate(payload, verifyAccess.token);
      if (response && response.data) {
        const successMessage = response.data.message;
        setToast &&
          setToast({
            type: "success",
            message: successMessage,
          });
        fetchDatas();
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        {loadingCount ? (
          <>
            <Skeleton variant="rounded" width={165} height={95} />
            <Skeleton variant="rounded" width={165} height={95} />
          </>
        ) : (
          <>
            <Box
              onClick={() => (fileBugCount === 0 ? "" : handleClick1())}
              sx={{
                display: "none",
                width: "165px",
                cursor: fileBugCount === 0 ? "not-allowed" : "pointer",
                padding: "14px 12px",
                border: isActive1 ? "2px solid" : "1px solid",
                borderRadius: "16px",
                color: isActive1 ? "#333" : "#696969",
                backgroundColor: isActive1 ? "#FFF8EC" : "transparent",
                borderColor: isActive1 ? "#FFA737" : "#D2D2D0",
                "&:hover": {
                  backgroundColor: "#ffa7371f",
                },
              }}
            >
              <Box
                sx={{
                  marginBottom: "4px",
                  fontSize: "28px",
                  fontWeight: "600",
                  fontFamily: "PolySans_Neutral",
                }}
              >
                {fileBugCount}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  fontWeight: isActive1 ? "600" : "400",
                }}
              >
                Bug reports
                <span>
                  <IconComponent
                    name="filter"
                    fill={isActive1 ? iconColors.active : iconColors.inactive}
                  />
                </span>
              </Box>
            </Box>
            <Box
              onClick={() => (fileScheduledCount === 0 ? "" : handleClick2())}
              sx={{
                display: "none",
                width: "165px",
                cursor: fileScheduledCount === 0 ? "not-allowed" : "pointer",
                padding: "16px 12px",
                border: isActive2 ? "2px solid" : "1px solid",
                borderRadius: "16px",
                color: isActive2 ? "#333" : "#696969",
                backgroundColor: isActive2 ? "#FFF8EC" : "transparent",
                borderColor: isActive2 ? "#FFA737" : "#D2D2D0",
                "&:hover": {
                  backgroundColor: "#ffa7371f",
                },
              }}
            >
              <Box
                sx={{
                  marginBottom: "4px",
                  fontSize: "28px",
                  fontWeight: "600",
                  fontFamily: "PolySans_Neutral",
                }}
              >
                {fileScheduledCount}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  fontWeight: isActive2 ? "600" : "400",
                }}
              >
                Files scheduled
                <span>
                  <IconComponent
                    name="filter"
                    fill={isActive2 ? iconColors.active : iconColors.inactive}
                  />
                </span>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {/* Tab Component */}
      {loadingTableCount ? (
        <>
          <Skeleton
            variant="rounded"
            width={"101%"}
            height={"calc(100vh - 310px)"}
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "-8px",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: { background: "#FFA737", height: "3px" },
                }}
              >
                <Tab
                  {...a11yProps(0)}
                  sx={{
                    "&.MuiTab-root": {
                      color: "#696969",
                      padding: "8px 12px",
                      borderBottom: "none !important",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "orange",
                      height: 3,
                    },
                    "&.MuiTab-root.Mui-selected": {
                      color: "#333",
                    },
                    "&:hover": {
                      backgroundColor: "#ffa7371f",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    },
                  }}
                  label={
                    <Typography>
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        All
                      </span>{" "}
                      <span style={{ fontWeight: "400", fontSize: "16px" }}>
                        {allCount}
                      </span>
                    </Typography>
                  }
                />
                <Tab
                  {...a11yProps(1)}
                  sx={{
                    "&.MuiTab-root": {
                      color: "#696969",
                      padding: "8px 12px",
                    },
                    "&.Mui-selected": {
                      color: "#333",
                    },
                    "&:hover": {
                      backgroundColor: "#ffa7371f",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    },
                  }}
                  label={
                    <Typography>
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Published files
                      </span>{" "}
                      <span style={{ fontWeight: "400", fontSize: "16px" }}>
                        {publishedCount}
                      </span>
                    </Typography>
                  }
                />
                <Tab
                  {...a11yProps(2)}
                  sx={{
                    "&.MuiTab-root": {
                      color: "#696969",
                      padding: "8px 12px",
                    },
                    "&.Mui-selected": {
                      color: "#333",
                    },
                    "&:hover": {
                      backgroundColor: "#ffa7371f",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    },
                  }}
                  label={
                    <Typography>
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Un-published files
                      </span>{" "}
                      <span style={{ fontWeight: "400", fontSize: "16px" }}>
                        {unpublishedCount}
                      </span>
                    </Typography>
                  }
                />
              </Tabs>
            </Box>
            {selectedRowCount === 0 && (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #FFA737",
                  height: "34px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#333",
                  paddingLeft: "12px",
                  cursor: "pointer",
                  backgroundColor:
                    anchorEl !== null ? "#FFEDD1" : "transparent",
                  "&:hover": {
                    backgroundColor: "#ffa7371f",
                  },
                }}
                onClick={handleClick}
              >
                Export CSV{" "}
                <Box
                  sx={{
                    borderLeft: "1px solid #FFA737",
                    height: "34px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    marginLeft: "12px",
                  }}
                >
                  <IconComponent name="userArrowDown" fill={"#333333"} />
                </Box>
              </Typography>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                  border: "1px solid #D2D2D0",
                  borderRadius: "8px",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  width: "265px",
                }}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box
                  sx={{
                    background: "trasparent",
                    boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
                  }}
                >
                  <Typography
                    onClick={handleFullExportCsv}
                    sx={{
                      padding: "12px 16px",
                      fontSize: "14px",
                      fontWeight: "400",
                      borderBottom: "1px solid #D2D2D0",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#ffa7371f",
                      },
                    }}
                  >
                    Export full list as CSV{" "}
                    <span style={{ color: "#696969" }}>({rowData.length})</span>
                  </Typography>
                  <Typography
                    onClick={handlePageExportCsv}
                    sx={{
                      padding: "12px 16px",
                      fontSize: "14px",
                      fontWeight: "400",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#ffa7371f",
                      },
                    }}
                  >
                    Export page to CSV{" "}
                    <span style={{ color: "#696969" }}>{`${(currentPage - 1) * 15 + 1
                      }-${Math.min(currentPage * 15, rowData.length)} of ${rowData.length
                      }`}</span>
                  </Typography>
                </Box>
              </Box>
            </Popover>
          </Box>
          {toast && (
            <ToastMessage
              type={toast.type as "success" | "error"}
              message={toast.message}
              onClose={() => setToast(null)}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: selectedRowCount !== 0 ? "3px" : "",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                {selectedRowCount > 0
                  ? `${selectedRowCount} selected`
                  : "Filter by"}
              </Typography>
              {selectedRowCount === 0 && (
                <FilterComponent
                  setFilters={setFiltersOption}
                  subjectData={subjectData}
                  typeData={typeData}
                />
              )}
              {selectedRowCount !== 0 && (
                <SelectedFilter
                  handleSelectedFilter={handleSelectedFilter}
                  selectedRowCount={selectedRowCount}
                  rowData={rowData}
                  selectedRowIds={selectedRowIds}
                />
              )}
            </Box>
            {selectedRowCount === 0 && (
              <SearchComponent
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            )}
          </Box>
          <Box>
            <CustomTabPanel value={value} index={0}>
              <TableComponent
                isLoading={isLoading}
                rows={rowData}
                rowCountState={rowCountState}
                currentPage={currentPage}
                onSelectionChange={handleSelectionChange}
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableComponent
                rows={rowData}
                isLoading={isLoading}
                rowCountState={rowCountState}
                currentPage={currentPage}
                onSelectionChange={handleSelectionChange}
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TableComponent
                isLoading={isLoading}
                rows={rowData}
                rowCountState={rowCountState}
                currentPage={currentPage}
                onSelectionChange={handleSelectionChange}
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
              />
            </CustomTabPanel>
          </Box>
        </>
      )}
    </>
  );
};
