import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice, authSliceOtp } from "./AuthSlice";
import { errorSlice } from "./error.slice";
import { fileLibrarySlice } from "./filelibrary.slice";
import { subjectLibrarySlice } from "./student.slice";
import { bugLibrarySlice } from "./bug.slice";
import { uploaderReducer } from "./uploaderSlice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  error: errorSlice.reducer,
  authOtp: authSliceOtp.reducer,
  fileLibrary: fileLibrarySlice.reducer,
  bugLibrary: bugLibrarySlice.reducer,
  studentLibrary: subjectLibrarySlice.reducer,
  uploader: uploaderReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
