// uploaderSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";



interface FileProgress {
    fileId: string;
    fileName: string;
    progress: number;
    status: 'uploading' | 'completed' | 'failed';
  }
interface UploaderState {
  open: boolean;
  minimized: boolean;
  loading: boolean;
  files: FileProgress[];  // Track multiple files
}

const initialState: UploaderState = {
  open: false,
  minimized: false,
  loading: false,
  files:[]
};

const uploaderSlice = createSlice({
  name: "uploader",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setMinimized: (state, action: PayloadAction<boolean>) => {
      state.minimized = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addFile: (state, action: PayloadAction<FileProgress>) => {
        state.files.push(action.payload);
    },
    updateProgress: (state, action: PayloadAction<{ fileId: string; progress: number }>) => {
    const file = state.files.find((f) => f.fileId === action.payload.fileId);
    if (file) file.progress = action.payload.progress;
    },
    setFileStatus: (state, action: PayloadAction<{ fileId: string; status: 'completed' | 'failed' }>) => {
    const file = state.files.find((f) => f.fileId === action.payload.fileId);
    if (file) file.status = action.payload.status;
    },
  },
});

export const { setOpen, setMinimized,setLoading,addFile,updateProgress,setFileStatus } = uploaderSlice.actions;
export const uploaderReducer = uploaderSlice.reducer;
