import { Box, Typography } from "@mui/material";
import React from "react";

interface statusProps {
  status: string;
  type: string;
  dropdown?: boolean;
}

const data = [
  {
    label: "Needs attention",
    status: "needs attention",
    color: "#C61341",
    bgColor: "#FFEEF2",
    type: "bugs",
  },
  {
    label: "In review",
    status: "in review",
    color: "#0069DB",
    bgColor: "#E6F2FF",
    type: "bugs",
  },
  {
    label: "Resolved",
    status: "resolved",
    color: "#007634",
    bgColor: "#E6F6ED",
    type: "bugs",
  },
  {
    label: "Scheduled",
    status: "SCHEDULED",
    color: "#0069DB",
    bgColor: "#E6F2FF",
    type: "published",
  },
  {
    label: "Draft",
    status: "DRAFTS",
    color: "#ffddc5",
    bgColor: "#994d00",
    type: "published",
  },
  {
    label: "Published",
    status: "PUBLISHED",
    color: "#007634",
    bgColor: "#E6F6ED",
    type: "published",
  },
  {
    label: "Not Started",
    status: "new",
    color: "#3B4478",
    bgColor: "#EBECF1",
    type: "status",
  },
  {
    label: "In review",
    status: "in_review",
    color: "#B85800",
    bgColor: "#FFF0E2",
    type: "status",
  },
  {
    label: "Completed",
    status: "completed",
    color: "#007668",
    bgColor: "#E3F5F5",
    type: "status",
  },
];

const StatusText = ({ status = "new", type = "bugs" }: statusProps) => {
  const getStatus = data.find((i) => i.status == status && i.type == type);
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={"8px"}
      alignItems={"center"}
      bgcolor={getStatus?.bgColor}
      paddingX={"8px"}
      paddingY={"4px"}
      borderRadius={"16px"}
      width={"fit-content"}
    >
      <Box
        width={"7px"}
        height={"7px"}
        bgcolor={getStatus?.color}
        borderRadius={"50%"}
        display={"inline-block"}
      />
      <Typography color={getStatus?.color} variant="detailBold">
        {getStatus?.label}
      </Typography>
    </Box>
  );
};

export default StatusText;
