import { v4 as uuidv4 } from "uuid";
import { AppDispatch } from "../../store";
import { addFile, setFileStatus, setLoading, updateProgress } from "../../store/uploaderSlice";
import client from "./apiClientFileUpload";
import Cookies from "js-cookie";

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_CONCURRENT_UPLOADS = 4;

const uploadChunk = async (fileChunk: Blob, fileId: string, chunkNumber: number, totalChunks: number, fileType: string, fileName: string) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString ? JSON.parse(verifyAccessString) : null;

  const formData = new FormData();
  formData.append("originalname", fileName);
  formData.append("chunkNumber", `${chunkNumber}`);
  formData.append("totalChunks", `${totalChunks}`);
  formData.append("uploadId", '');
  formData.append("parts", JSON.stringify([]));
  formData.append("chunk", new Blob([fileChunk], { type: fileType }), fileName); 


  await client.post("/class_recording/chunk-upload-v1", formData, {
    headers: {
      Authorization: `Bearer ${verifyAccess.token}`,
      "Content-Type": "multipart/form-data",
    }
  });
};

const uploadFile = (file: File, fileId: string) => async (dispatch: AppDispatch) => {
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  let uploadedChunks = 0;

  const uploadSingleChunk = async (chunkNumber: number) => {
    const start = chunkNumber * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const fileChunk = file.slice(start, end);

    try {
      await uploadChunk(fileChunk, fileId, chunkNumber + 1, totalChunks, file.type, file.name);
      uploadedChunks++;
      const progress = Math.round((uploadedChunks / totalChunks) * 100);
      dispatch(updateProgress({ fileId, progress }));
    } catch (error) {
      dispatch(setFileStatus({ fileId, status: "failed" }));
      throw error;
    }
  };

  const chunkUploadPromises: Promise<void>[] = [];
  for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
    const uploadPromise = uploadSingleChunk(chunkNumber);
    chunkUploadPromises.push(uploadPromise);

    if (chunkUploadPromises.length >= MAX_CONCURRENT_UPLOADS) {
      await Promise.all(chunkUploadPromises);
      chunkUploadPromises.length = 0;
    }
  }

  await Promise.all(chunkUploadPromises);
  dispatch(setFileStatus({ fileId, status: "completed" }));
};

export const uploadFilesInBatches = (files: File[]) => (dispatch: AppDispatch) => {
    const queue = files.slice();
    dispatch(setLoading(true));
    const activeUploads: Promise<void>[] = [];
  
    const processNextFile = () => {
      if (queue.length === 0 && activeUploads.length === 0) {
        dispatch(setLoading(false)); // All files and chunks are uploaded
        return;
      }
  
      const file = queue.shift();
      if (file) {
        const fileId = uuidv4();
        dispatch(addFile({ fileId, fileName: file.name, progress: 0, status: "uploading" }));
  
        const uploadPromise = dispatch(uploadFile(file, fileId)).then(() => {
          activeUploads.splice(activeUploads.indexOf(uploadPromise), 1);
          processNextFile(); // Check for next file in queue
        });
  
        activeUploads.push(uploadPromise);
        if (activeUploads.length < MAX_CONCURRENT_UPLOADS) processNextFile();
      }
    };
  
    // Start the initial batch
    for (let i = 0; i < MAX_CONCURRENT_UPLOADS; i++) processNextFile();
  };
